html, body {
  height: 100vh;
  margin: 0;
  font-family: sans-serif;
}

.leaflet-container {
  height: 100%;
  width: 100%;
  max-width: 100%;
  max-height: 100%;
}

#map {
  position: absolute;
}

#app {
  position: absolute;
  z-index: 1000;
}

.search-container {
  position: fixed;
  top: 16px;
  left: 54px;
  z-index: 10000;
  right: 16px;
}

.search {
  width: 100%;
  max-width: 340px;
}

.popup-container {
  width: 100%;
  display: flex;
  flex-direction: column;  
}
.popup-container h3,
.popup-container p {
  margin: 0 !important;
}
.popup-container .popup-row {
  margin-top: 1em;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  gap: 1em;
}
.popup-container .popup-row > * {
  width: 50%;
}
.popup-container .popup-row .popup-text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.popup-container .popup-row img {
  width: 100%;
}